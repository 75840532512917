<template>
  <div class="container">
    <b-form @submit="onSubmit">
      <div v-if="contactInfo" class="left">
        <PageTitle subtitle="Contact Information"/>
        <div class="d-flex" v-if="admin">
          <div class="ml-auto mr-2">
            Make for user
          </div>
          <b-form-select
              :options="userOpts"
              class="user-select"
              v-model="$route.query.user"
              disabled
          />
        </div>
        <b-form-group
            label="Name"
            label-for="name"
            v-if="fundraiser.contact.name"
        >
          <b-form-input id="name" type="text" required v-model="form.contact.name"/>
        </b-form-group>
        <b-form-group
            label="Email Address"
            label-for="email-address"
            v-if="fundraiser.contact.email"
        >
          <b-form-input id="email-address" type="email" v-model="form.contact.email"></b-form-input>
        </b-form-group>
        <b-form-group
            label="Phone Number"
            label-for="phone-number"
            v-if="fundraiser.contact.phoneNumber"
        >
          <b-form-input id="phone-number" type="tel"
                        v-model="form.contact.phoneNumber"></b-form-input>
        </b-form-group>
      </div>

      <div class="left">
        <PageTitle subtitle="Organization Information"/>
        <b-form-group
            label="Organization Name"
            label-for="org-name"
            v-if="fundraiser.orgInfo.name"
        >
          <b-form-input required id="org-name" v-model="form.orgInfo.name"/>
        </b-form-group>
        <b-form-group
            label="Organization Address"
            label-for="org-addr"
            v-if="fundraiser.orgInfo.address"
        >
          <b-form-input required id="org-addr" v-model="form.orgInfo.address"/>
        </b-form-group>

        <b-form-group
            label="Organization Phone Number"
            label-for="org-phone"
        >
          <b-form-input required id="org-phone" v-model="form.orgInfo.phone"/>
        </b-form-group>

        <b-form-group
            label="Organization Email"
            label-for="org-email"
        >
          <b-form-input required id="org-email" v-model="form.orgInfo.email"/>
        </b-form-group>

      </div>

      <div class="left">
        <PageTitle subtitle="Restaurant"/>
        <b-form-group
            label="Restaurant"
            label-for="restaurant-select"
            description="Select your desired restaurant"
        >
          <b-form-select
              :options="restaurantOptions"
              v-model="form.restaurant"
              required
          />
        </b-form-group>

        <b-form-group
            label="Preferred Date"
            label-for="date-select"
            description="Select your desired date"
        >
          <b-form-datepicker
              id="date-select"
              v-model="form.date"
              required
              :date-disabled-fn="dateDisabled"
          />
        </b-form-group>
      </div>
      <div class="my-5 d-flex flex-wrap">
        <b-btn class="mr-3 ml-auto" variant="success" type="submit">
          Apply
        </b-btn>
        <b-btn variant="danger" @click="() => {$emit('cancelApp')}">Cancel</b-btn>
      </div>


    </b-form>
  </div>

</template>

<script>
import PageTitle from "@/components/shared/PageTitle";
import {mapState} from 'vuex';
import {addDoc, collection, getFirestore, onSnapshot, query} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';
import admin from "@/components/shared/mixins/admin";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';

export default {
  name: "FundraiserApplicationForm",
  components: {PageTitle, VueTypeaheadBootstrap},
  props: {
    'fundraiser': {},
    'id': {},
    'agreed': {
      type: Boolean
    },
    approved: {
      type: Boolean
    }
  },
  mixins: [admin],
  methods: {
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      if (this.fundraiser.name === 'Dining To Donate') {
        return (weekday === 0 || weekday === 6 || weekday === 5)
      }
      if (this.fundraiser.name === 'Together We Care') {
        return (weekday !== 2);
      }
      return !(weekday === 0 || weekday === 6)
    },
    onSubmit(event) {
      event.preventDefault();
      let data = {
        ...this.form,
        date: Date.parse(this.form.date) + (new Date(this.form.date).getTimezoneOffset() * 60000),
        user: this.admin ? (this.$route?.query?.user || getAuth().currentUser.uid) : getAuth().currentUser.uid,
        fundraiser: this.id,
        agreed: this.agreed,
      }
      if (!data.date){
        this.$notify({
          group: 'main-notifications',
          title: "Error",
          message: "Invalid date selected.",
          type: "error"
        })
        return;
      }
      if (this.admin && this.approved) {
        data.approved = true;
      }
      addDoc(collection(getFirestore(), "events"),data).then(() => {
        this.$notify({
          group: 'main-notifications',
          type: 'success',
          title: 'Applied',
          text: 'Event submitted, find the event on the Events page.'
        })
        this.$emit('applied')
        this.form = {
          date: '',
          contact: {
            name: '',
            email: '',
            phoneNumber: '',
          },
          orgInfo: {
            name: '',
            address: '',
          },
          restaurant: '',
        }
      }).catch(e => {
        this.$notify({
          group: 'main-notifications',
          type: 'error',
          title: 'Error submitting event',
          text: e.message
        })
      })
    }
  },
  computed: {
    ...mapState('restaurants', ['states']),
    contactInfo() {
      let contact = this.fundraiser.contact;
      return contact?.email || contact?.phoneNumber || contact?.name
    },

    userOpts(){
      return this.users.map(u => {
        if (u){
          return {text: u.email, value: u.id}
        }
      });

    },

    restaurantOptions() {
      let opts = [];
      Object.keys(this.states).forEach(state => {
        const rests = this.states[state]
        opts.push({
          label: state,
          options: rests.filter(r => r.visible).map(r => {
            return {
              value: r.id,
              text: `${r.city} - ${r.address}`
            }
          })
        })
      })
      return opts;
    }

  },
  data() {
    return {
      form: {
        date: '',
        contact: {
          name: '',
          email: '',
          phoneNumber: '',
        },
        orgInfo: {
          name: '',
          address: '',
        },
        restaurant: '',
      },
      users: [],
      selectedUser: null,
      testVal: ''
    }
  },
  mounted(){
    if (this.admin){
      const usersRef = collection(getFirestore(), "users");
      const q = query(usersRef);
      onSnapshot(q, (qSnap) => {
        this.users = [];
        qSnap.forEach((user) => {
          this.users.push({...user.data(), id: user.id});
          if (this.$route.query?.user && user.id === this.$route.query.user){
            this.form.contact.email = user.data().email
          }
        });
      });
    }
  }
}
</script>

<style scoped>

.left {
  text-align: left;
}

.restaurant-row {
  max-width: 500px;
}

.user-select {
  max-width: 300px;
}

.selected-restaurant {
  border: 1px solid green;
  padding: 10px;
}

.container {
  max-width: 600px;
}

</style>
